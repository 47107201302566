.clientesFondo {
    position: fixed;
    width: 100%;
    height: 88.5%;
    background: #E5E5E5;
    overflow: auto;
}

.seguimientoClientesFondo {
    position: fixed;
    width: 100%;
    height: 88.5%;
    background: #E5E5E5;
    overflow: auto;
}

.tituloClientes {
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: "Calibri", sans-serif, serif;
    font-size: 24pt;
    margin-top: 1%;
}

.tituloClientesBoton {
    align-items: center;
    display: flex;
    justify-content: left;
    font-family: "Calibri", sans-serif, serif;
    font-size: 24pt;
    margin-top: 0.5%;
    margin-left: 1%;
}

.nuevoClienteButtonIco {
    position: relative;
    max-width: 40px;
    margin-top: -2%;
}

.eliminarButtonIco {
    position: relative;
    max-width: 20px;
    margin-top: -80%;
}

.contenedorTablaClientes {
    position: relative;
    left: 5%;
    width: 90%;
}

.btn-outline-fpi {
    --bs-btn-bg: transparent;
    --bs-btn-color: black;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #0B6D8D;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #1A8DB6;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
    margin-top: -3%;
}

.btn-outline-fpi-eliminar {
    --bs-btn-bg: transparent;
    --bs-btn-color: black;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #9E0B0B;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #1A8DB6;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
    height: 25px;
}

.btn-modal-guardar {
    --bs-btn-bg: #0B6D8D;
    --bs-btn-color: white;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #1A8DB6;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #0B6D8D;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
}

.listaClientesPaginationControl {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}

.PaginationControl {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: black;
    --bs-pagination-bg: #E5E5E5;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #0B6D8D;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: #E5E5E5;
    --bs-pagination-hover-bg: #1A8DB6;
    --bs-pagination-hover-border-color: #0B6D8D;
    --bs-pagination-focus-color: black;
    --bs-pagination-focus-bg: #E5E5E5;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(15, 147, 191, 0.18);
    --bs-pagination-active-color: white;
    --bs-pagination-active-bg: #0B6D8D;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none
}

.dropdown-menu.show {
    background-color: #E5E5E5;
}

.dropdown-item {
    color: black;
}

.dropdown-toggle.show.btn.btn-primary {
    --bs-btn-bg: #0B6D8D;
    --bs-btn-color: white;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #1A8DB6;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #0B6D8D;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
}

.dropdown-toggle.btn-primary {
    --bs-btn-bg: #E3E3E3;
    --bs-btn-color: black;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #1A8DB6;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #0B6D8D;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
}

.contenedorTablaSegumientoClientes {
    position: relative;
    left: 1%;
    width: 90%;
}

.encabezadoFlotante {
    position: sticky;
    top: 0px;
    background: #E3E3E3;
}

#seguimientoClientesEcabezadoDatosCliente {
    background: #0B6D8D;
    color: white;
}

#seguimientoClientesEcabezadoDatosCredito {
    background: #1A8DB6;
    color: white;
}

#seguimientoClientesEcabezadoDatosRelacionFPI {
    background: #0B6D8D;
    color: white;
}

.bordeIzquierdo {
    border-left: black;
}

.bordeDerecho {
    border-right: black;
}

.seguimientoClientesLink {
    color: black;
    text-decoration: none;
}

    .seguimientoClientesLink:hover {
        color: white;
    }

.seguimientoClientesDivNombre {
    min-width: 250px;
}

.seguimientoClientesDivTipoCredito {
    min-width: 200px;
}

.seguimientoClientesDivEstado {
    width: 200px;
}

.seguimientoClientesCeldaBoton:hover {
    enable-background: initial;
    background: #1A8DB6;
    color: white;
}

#seguimientoClientesEcabezadoColumnaOrden {
    enable-background: initial;
    background: #1A8DB6;
    color: white;
}