.fondoGris {
    background: #E5E5E5;
}

.fondoAzul {
    background: linear-gradient(#0B6D8D, #1A8DB6 );
}

.clienteDetalleFondo {
    position: fixed;
    width: 100%;
    height: 89%;
    background: #E5E5E5;
    scroll-behavior: initial;
    overflow: auto;
}

.clienteDetalleTablaCliente {
    position: relative;
    width: 90%;
    min-width: 200px;
    min-height: 100px;
    left: 5%;
    top: 2%;
}

.clienteDetalleFondoTitulo:hover {
    background: #1A8DB6;
    color: white;
}

.inputboxclienteDetalle {
    background: #E5E5E5;
    border-style: ridge;
    inline-size: none;
    border-block-start: 0;
    border-left: 0;
    border-color: #0B6E8E;
    border-inline-width: thin;
    width: 100%;
}

.ModalDetalleClienteHeader {
    background: linear-gradient(#1A8DB6, #0B6D8D);
}

.ModalDetalleClienteBody {
    background: #E5E5E5;
}

.login-fpi-logo-letras-ModalDetalleClienteHeader {
    position: relative;
    max-width: 80px;
    margin-top: -2%;
}

.clienteDetalleAcordionEstudioCredito {
    position: relative;
    top: 1%;
    width: 90%;
    left: 5%;
}

.AcordionEstudioCreditoFondo {
    background: #E5E5E5;
}

.AcordionEstudioCreditoTablaCredito {
    position: relative;
    width: 100%;
    min-height: 100px;
    top: 2%;
}

.AcordionEstudioCreditoTablaOpcionesNegociacion {
    position: relative;
    width: 100%;
    min-height: 100px;
    top: 2%;
}

.AcordionEstudioCreditoTablaSeguros {
    position: relative;
    width: 100%;
    min-height: 100px;
    top: 2%;
}

.form-select {
    background: #E5E5E5;
    border-style: ridge;
    inline-size: none;
    border-block-start: 0;
    border-left: 0;
    border-color: #0B6E8E;
    border-inline-width: thin;
    width: 100%;
}

.accordion-button {
    background-color: #0B6D8D;
    color: white;
}

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        background-color: #1A8DB6;
        color: #EBEBEB;
    }

    .accordion-button:is(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23AQUICOLOCAELCOLOR'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

.AcordionEstudioCreditoHeader {
    display: flex;
    width: 70%;
    justify-content: center;
}

.btn-outline-fpi-generar-informe {
    display: flex;
    margin-top: -0.5%;
    border: 1px solid #FFFFFF;
    text-indent: 5px;
    padding-left: 4px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
}

    .btn-outline-fpi-generar-informe:hover {
        opacity: 1;
        background-color: #095770;
        color: white;
    }

.nuevoEstudioButtonIco {
    position: relative;
    max-width: 45px;
    max-height: 35px;
    margin-top: -2%;
}

.btn-outline-fpi-eliminar-informe {
    display: flex;
    margin-top: -0.5%;
    border: 1px solid #FFFFFF;
    text-indent: 5px;
    padding-left: 4px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
}

    .btn-outline-fpi-eliminar-informe:hover {
        opacity: 1;
        background-color: #9E0B0B;
        color: white;
    }

.eliminarEstudioButtonIco {
    position: relative;
    max-width: 45px;
    max-height: 50px;
    margin-top: -2%;
}

.tituloClienteDetalleBoton {
    margin-left: 2%
}
