.loginFormBackground {
    position: fixed;
    width: 100%;
    height: 89%;
    min-height: 20px;
    background-color: #F9F9F9;
    overflow: auto;
}

.login-fpi-logo-letras {
    position: relative;
    max-width: 220px;
    left: -73%;
    top: -21%
}

.recaptcha {
    margin-bottom: 5%;
}

.signin {
    display: block;
    position: relative;
    max-width: 450px;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(0,0,0,0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: inset 1px 1px 0 0 rgba(255,255,255,0.2), inset -1px -1px 0 0 rgba(0,0,0,0.2);
    -moz-box-shadow: inset 1px 1px 0 0 rgba(255,255,255,0.2), inset -1px -1px 0 0 rgba(0,0,0,0.2);
    box-shadow: inset 1px 1px 0 0 rgba(255,255,255,0.2), inset -1px -1px 0 0 rgba(0,0,0,0.2);
}

    .signin .avatar {
        width: 100px;
        height: 100px;
        margin: 0 auto 35px auto;
        border: 5px solid #fff;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        -webkit-pointer-events: none;
        -moz-pointer-events: none;
        pointer-events: none;
    }

        .signin .avatar:before {
            text-align: center;
            font-family: Ionicons;
            display: block;
            height: 100%;
            line-height: 100px;
            font-size: 5em;
        }

    .signin .inputrow {
        position: relative;
    }

        .signin .inputrow label {
            position: absolute;
            top: 12px;
            left: 10px;
        }

            .signin .inputrow label:before {
                color: #538a9a;
                opacity: 0.4;
                -webkit-transition: opacity 300ms 0 ease;
                -moz-transition: opacity 300ms 0 ease;
                transition: opacity 300ms 0 ease;
            }

    .signin input[type="email"],
    .signin input[type="password"] {
        padding: 10px 12px 10px 32px;
        display: block;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid rgba(255,255,255,0.5);
        background-color: #fff;
        color: #333;
        font-size: 1em;
        font-weight: 300;
        outline: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -webkit-transition: border-color 300ms 0 ease;
        -moz-transition: border-color 300ms 0 ease;
        transition: border-color 300ms 0 ease;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

        .signin input[type="email"]:focus + label:before,
        .signin input[type="password"]:focus + label:before {
            opacity: 1;
        }

    .signin input[type="submit"] {
        -webkit-appearance: none;
        height: 55px;
        width: 35%;
        padding: 10px 12px;
        margin-bottom: 10px;
        background-color: #0B6D8D;
        text-transform: uppercase;
        color: #fff;
        border: 0px;
        float: right;
        margin: 0;
        outline: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

        .signin input[type="submit"]:hover {
            background-color: #1A8DB6;
        }

        .signin input[type="submit"]:active {
            background-color: #4a7b89;
        }

        .signin input[type="submit"]:disabled {
            background-color: #C9C9C9;
        }

input[type="checkbox"] {
    display: none;
}

    input[type="checkbox"] + label {
        position: relative;
        padding-left: 36px;
        font-size: 0.6em;
        font-weight: normal;
        line-height: 16px;
        opacity: 0.8;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

        input[type="checkbox"] + label:before,
        input[type="checkbox"] + label:after {
            content: "";
            position: absolute;
            display: block;
            height: 16px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }

        input[type="checkbox"] + label:before {
            left: 0;
            top: -2px;
            width: 30px;
            background: rgba(0,0,0,0.3);
            -webkit-box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.3);
            -moz-box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.3);
            box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.3);
        }

        input[type="checkbox"] + label:after {
            opacity: 0.3;
            background: #fff;
            top: 0px;
            left: 2px;
            height: 12px;
            width: 12px;
            -webkit-transition: all 200ms 0 ease;
            -moz-transition: all 200ms 0 ease;
            transition: all 200ms 0 ease;
        }

    input[type="checkbox"]:checked + label {
        opacity: 1;
    }

        input[type="checkbox"]:checked + label:after {
            opacity: 1;
            left: 16px;
        }

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.fpispinner {
    max-width:101px;
    max-height:95px;
    -webkit-animation: 1.8s rotate linear infinite;
    animation: 1.8s rotate linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

}

.modalFpiSpinner {
    display:flex;
    position:relative;
    margin-left: 40%;
    width:101px;
    text-align: center !important;
    background: rgba(12, 102, 133, 0.9);
    border-radius: 100px;
}