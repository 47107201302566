.propuestaDetalleFondo{
    position:center;
    max-width:100%;
}

.propuestaDetalleEncabezado {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2%;
    grid-auto-rows: minmax(56.25px, auto);
    margin-top: 0.5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.fpi-logo-propuestaDetalle {
    grid-column: 1;
    grid-row: 1;
    width: 250px;
    height: 75px;
    
}

.propuestaDetalleTitulo {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    margin-top: 5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.propuestaDetalleContenido {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: normal;
    grid-auto-rows: minmax(56.25px, auto);
    margin-top: 0.5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.propuestaDetalleTablaDatosCliente {
    grid-column: 1;
    grid-row: 1/4;
    width: 80%;
}

.propuestaDetalleTablaOpcionesNegociacion {
    grid-column: 2;
    grid-row: 1/5;
    width: 80%;
}

.propuestaDetalleGraficoComportamientoCredito {
    display: block;
    grid-column: 1;
    grid-row: 5/9;
    width: 80%;
    max-height: 500px;
    align-content: center;
    border: solid;
    border-style: groove;
}

.propuestaDetalleGraficoDistribucionCuota {
    display: block;
    grid-column: 2;
    grid-row: 5/9;
    width: 80%;
    max-height: 500px;
    margin-top: 1%;
    align-content: center;
    border: solid;
    border-style: groove;
}

.propuestaDetalleTablaComportamientoDeuda {
    grid-column: 2;
    grid-row: 10/14;
    width: 80%;
}

.propuestaDetalleTablaSeguros {
    grid-column: 1;
    grid-row: 10/10;
    width: 80%;
}

.propuestaDetalleObservaciones {
    grid-column: 2;
    grid-row: 13;
    width: 80%;
}

.propuestaDetalleGraficoComportamientoSeguros {
    display:block;
    grid-column: 1;
    grid-row: 12/16;
    width: 80%;
    max-height: 350px;
    align-content: center;
    border: solid;
    border-style:groove;
}

.propuestaDetalleNegrita {
    color: #0B6D8D;
    font-weight: bold;
}

.propuestaDetalleObservacionesValida {
    color: #E46C0A;
    font-weight: bold;
}