.NavbarMenu {
    background: linear-gradient(#1A8DB6, #0B6D8D);
    color: white

}

.fpi-logo {
    max-width: 250px
}

.NavLinkMenu {
    color: white;
    text-decoration: none;
    margin-right: 2%;
    border-right: dotted;
    border-right-width: thin;
}

    .NavLinkMenu:hover {
        color: #F5811E;
        text-decoration: none;
    }

#basic-nav-dropdown-menu {
    color: white;
}
    #basic-nav-dropdown-menu:hover {
        color: #F5811E;
        text-decoration: none;
    }
