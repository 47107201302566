body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavbarMenu {
    background: linear-gradient(#1A8DB6, #0B6D8D);
    color: white

}

.fpi-logo {
    max-width: 250px
}

.NavLinkMenu {
    color: white;
    text-decoration: none;
    margin-right: 2%;
    border-right: dotted;
    border-right-width: thin;
}

    .NavLinkMenu:hover {
        color: #F5811E;
        text-decoration: none;
    }

#basic-nav-dropdown-menu {
    color: white;
}
    #basic-nav-dropdown-menu:hover {
        color: #F5811E;
        text-decoration: none;
    }

.loginFormBackground {
    position: fixed;
    width: 100%;
    height: 89%;
    min-height: 20px;
    background-color: #F9F9F9;
    overflow: auto;
}

.login-fpi-logo-letras {
    position: relative;
    max-width: 220px;
    left: -73%;
    top: -21%
}

.recaptcha {
    margin-bottom: 5%;
}

.signin {
    display: block;
    position: relative;
    max-width: 450px;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
    box-shadow: inset 1px 1px 0 0 rgba(255,255,255,0.2), inset -1px -1px 0 0 rgba(0,0,0,0.2);
}

    .signin .avatar {
        width: 100px;
        height: 100px;
        margin: 0 auto 35px auto;
        border: 5px solid #fff;
        border-radius: 100%;
        -webkit-pointer-events: none;
        -moz-pointer-events: none;
        pointer-events: none;
    }

        .signin .avatar:before {
            text-align: center;
            font-family: Ionicons;
            display: block;
            height: 100%;
            line-height: 100px;
            font-size: 5em;
        }

    .signin .inputrow {
        position: relative;
    }

        .signin .inputrow label {
            position: absolute;
            top: 12px;
            left: 10px;
        }

            .signin .inputrow label:before {
                color: #538a9a;
                opacity: 0.4;
                transition: opacity 300ms 0 ease;
            }

    .signin input[type="email"],
    .signin input[type="password"] {
        padding: 10px 12px 10px 32px;
        display: block;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid rgba(255,255,255,0.5);
        background-color: #fff;
        color: #333;
        font-size: 1em;
        font-weight: 300;
        outline: none;
        border-radius: 5px;
        transition: border-color 300ms 0 ease;
        box-sizing: border-box;
    }

        .signin input[type="email"]:focus + label:before,
        .signin input[type="password"]:focus + label:before {
            opacity: 1;
        }

    .signin input[type="submit"] {
        -webkit-appearance: none;
        height: 55px;
        width: 35%;
        padding: 10px 12px;
        margin-bottom: 10px;
        background-color: #0B6D8D;
        text-transform: uppercase;
        color: #fff;
        border: 0px;
        float: right;
        margin: 0;
        outline: none;
        border-radius: 5px;
    }

        .signin input[type="submit"]:hover {
            background-color: #1A8DB6;
        }

        .signin input[type="submit"]:active {
            background-color: #4a7b89;
        }

        .signin input[type="submit"]:disabled {
            background-color: #C9C9C9;
        }

input[type="checkbox"] {
    display: none;
}

    input[type="checkbox"] + label {
        position: relative;
        padding-left: 36px;
        font-size: 0.6em;
        font-weight: normal;
        line-height: 16px;
        opacity: 0.8;
        text-transform: uppercase;
        -webkit-user-select: none;
        user-select: none;
    }

        input[type="checkbox"] + label:before,
        input[type="checkbox"] + label:after {
            content: "";
            position: absolute;
            display: block;
            height: 16px;
            border-radius: 30px;
        }

        input[type="checkbox"] + label:before {
            left: 0;
            top: -2px;
            width: 30px;
            background: rgba(0,0,0,0.3);
            box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.3);
        }

        input[type="checkbox"] + label:after {
            opacity: 0.3;
            background: #fff;
            top: 0px;
            left: 2px;
            height: 12px;
            width: 12px;
            transition: all 200ms 0 ease;
        }

    input[type="checkbox"]:checked + label {
        opacity: 1;
    }

        input[type="checkbox"]:checked + label:after {
            opacity: 1;
            left: 16px;
        }

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.fpispinner {
    max-width:101px;
    max-height:95px;
    -webkit-animation: 1.8s rotate linear infinite;
    animation: 1.8s rotate linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

}

.modalFpiSpinner {
    display:flex;
    position:relative;
    margin-left: 40%;
    width:101px;
    text-align: center !important;
    background: rgba(12, 102, 133, 0.9);
    border-radius: 100px;
}
.clientesFondo {
    position: fixed;
    width: 100%;
    height: 88.5%;
    background: #E5E5E5;
    overflow: auto;
}

.seguimientoClientesFondo {
    position: fixed;
    width: 100%;
    height: 88.5%;
    background: #E5E5E5;
    overflow: auto;
}

.tituloClientes {
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: "Calibri", sans-serif, serif;
    font-size: 24pt;
    margin-top: 1%;
}

.tituloClientesBoton {
    align-items: center;
    display: flex;
    justify-content: left;
    font-family: "Calibri", sans-serif, serif;
    font-size: 24pt;
    margin-top: 0.5%;
    margin-left: 1%;
}

.nuevoClienteButtonIco {
    position: relative;
    max-width: 40px;
    margin-top: -2%;
}

.eliminarButtonIco {
    position: relative;
    max-width: 20px;
    margin-top: -80%;
}

.contenedorTablaClientes {
    position: relative;
    left: 5%;
    width: 90%;
}

.btn-outline-fpi {
    --bs-btn-bg: transparent;
    --bs-btn-color: black;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #0B6D8D;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #1A8DB6;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
    margin-top: -3%;
}

.btn-outline-fpi-eliminar {
    --bs-btn-bg: transparent;
    --bs-btn-color: black;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #9E0B0B;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #1A8DB6;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
    height: 25px;
}

.btn-modal-guardar {
    --bs-btn-bg: #0B6D8D;
    --bs-btn-color: white;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #1A8DB6;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #0B6D8D;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
}

.listaClientesPaginationControl {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}

.PaginationControl {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: black;
    --bs-pagination-bg: #E5E5E5;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #0B6D8D;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: #E5E5E5;
    --bs-pagination-hover-bg: #1A8DB6;
    --bs-pagination-hover-border-color: #0B6D8D;
    --bs-pagination-focus-color: black;
    --bs-pagination-focus-bg: #E5E5E5;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(15, 147, 191, 0.18);
    --bs-pagination-active-color: white;
    --bs-pagination-active-bg: #0B6D8D;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none
}

.dropdown-menu.show {
    background-color: #E5E5E5;
}

.dropdown-item {
    color: black;
}

.dropdown-toggle.show.btn.btn-primary {
    --bs-btn-bg: #0B6D8D;
    --bs-btn-color: white;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #1A8DB6;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #0B6D8D;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
}

.dropdown-toggle.btn-primary {
    --bs-btn-bg: #E3E3E3;
    --bs-btn-color: black;
    --bs-btn-border-color: #1A8DB6;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #1A8DB6;
    --bs-btn-hover-border-color: #1A8DB6;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #E3E3E3;
    --bs-btn-active-bg: #0B6D8D;
    --bs-btn-active-border-color: #0B6D8D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0B6D8D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0B6D8D;
    --bs-gradient: none;
}

.contenedorTablaSegumientoClientes {
    position: relative;
    left: 1%;
    width: 90%;
}

.encabezadoFlotante {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background: #E3E3E3;
}

#seguimientoClientesEcabezadoDatosCliente {
    background: #0B6D8D;
    color: white;
}

#seguimientoClientesEcabezadoDatosCredito {
    background: #1A8DB6;
    color: white;
}

#seguimientoClientesEcabezadoDatosRelacionFPI {
    background: #0B6D8D;
    color: white;
}

.bordeIzquierdo {
    border-left: black;
}

.bordeDerecho {
    border-right: black;
}

.seguimientoClientesLink {
    color: black;
    text-decoration: none;
}

    .seguimientoClientesLink:hover {
        color: white;
    }

.seguimientoClientesDivNombre {
    min-width: 250px;
}

.seguimientoClientesDivTipoCredito {
    min-width: 200px;
}

.seguimientoClientesDivEstado {
    width: 200px;
}

.seguimientoClientesCeldaBoton:hover {
    enable-background: initial;
    background: #1A8DB6;
    color: white;
}

#seguimientoClientesEcabezadoColumnaOrden {
    enable-background: initial;
    background: #1A8DB6;
    color: white;
}
.fondoGris {
    background: #E5E5E5;
}

.fondoAzul {
    background: linear-gradient(#0B6D8D, #1A8DB6 );
}

.clienteDetalleFondo {
    position: fixed;
    width: 100%;
    height: 89%;
    background: #E5E5E5;
    scroll-behavior: initial;
    overflow: auto;
}

.clienteDetalleTablaCliente {
    position: relative;
    width: 90%;
    min-width: 200px;
    min-height: 100px;
    left: 5%;
    top: 2%;
}

.clienteDetalleFondoTitulo:hover {
    background: #1A8DB6;
    color: white;
}

.inputboxclienteDetalle {
    background: #E5E5E5;
    border-style: ridge;
    inline-size: none;
    -webkit-border-before: 0;
            border-block-start: 0;
    border-left: 0;
    border-color: #0B6E8E;
    border-inline-width: thin;
    width: 100%;
}

.ModalDetalleClienteHeader {
    background: linear-gradient(#1A8DB6, #0B6D8D);
}

.ModalDetalleClienteBody {
    background: #E5E5E5;
}

.login-fpi-logo-letras-ModalDetalleClienteHeader {
    position: relative;
    max-width: 80px;
    margin-top: -2%;
}

.clienteDetalleAcordionEstudioCredito {
    position: relative;
    top: 1%;
    width: 90%;
    left: 5%;
}

.AcordionEstudioCreditoFondo {
    background: #E5E5E5;
}

.AcordionEstudioCreditoTablaCredito {
    position: relative;
    width: 100%;
    min-height: 100px;
    top: 2%;
}

.AcordionEstudioCreditoTablaOpcionesNegociacion {
    position: relative;
    width: 100%;
    min-height: 100px;
    top: 2%;
}

.AcordionEstudioCreditoTablaSeguros {
    position: relative;
    width: 100%;
    min-height: 100px;
    top: 2%;
}

.form-select {
    background: #E5E5E5;
    border-style: ridge;
    inline-size: none;
    -webkit-border-before: 0;
            border-block-start: 0;
    border-left: 0;
    border-color: #0B6E8E;
    border-inline-width: thin;
    width: 100%;
}

.accordion-button {
    background-color: #0B6D8D;
    color: white;
}

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        background-color: #1A8DB6;
        color: #EBEBEB;
    }

    .accordion-button:is(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23AQUICOLOCAELCOLOR'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

.AcordionEstudioCreditoHeader {
    display: flex;
    width: 70%;
    justify-content: center;
}

.btn-outline-fpi-generar-informe {
    display: flex;
    margin-top: -0.5%;
    border: 1px solid #FFFFFF;
    text-indent: 5px;
    padding-left: 4px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
}

    .btn-outline-fpi-generar-informe:hover {
        opacity: 1;
        background-color: #095770;
        color: white;
    }

.nuevoEstudioButtonIco {
    position: relative;
    max-width: 45px;
    max-height: 35px;
    margin-top: -2%;
}

.btn-outline-fpi-eliminar-informe {
    display: flex;
    margin-top: -0.5%;
    border: 1px solid #FFFFFF;
    text-indent: 5px;
    padding-left: 4px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
}

    .btn-outline-fpi-eliminar-informe:hover {
        opacity: 1;
        background-color: #9E0B0B;
        color: white;
    }

.eliminarEstudioButtonIco {
    position: relative;
    max-width: 45px;
    max-height: 50px;
    margin-top: -2%;
}

.tituloClienteDetalleBoton {
    margin-left: 2%
}

.propuestaDetalleFondo{
    position:center;
    max-width:100%;
}

.propuestaDetalleEncabezado {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2%;
    grid-auto-rows: minmax(56.25px, auto);
    margin-top: 0.5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.fpi-logo-propuestaDetalle {
    grid-column: 1;
    grid-row: 1;
    width: 250px;
    height: 75px;
    
}

.propuestaDetalleTitulo {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    margin-top: 5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.propuestaDetalleContenido {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: normal;
    grid-auto-rows: minmax(56.25px, auto);
    margin-top: 0.5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.propuestaDetalleTablaDatosCliente {
    grid-column: 1;
    grid-row: 1/4;
    width: 80%;
}

.propuestaDetalleTablaOpcionesNegociacion {
    grid-column: 2;
    grid-row: 1/5;
    width: 80%;
}

.propuestaDetalleGraficoComportamientoCredito {
    display: block;
    grid-column: 1;
    grid-row: 5/9;
    width: 80%;
    max-height: 500px;
    align-content: center;
    border: solid;
    border-style: groove;
}

.propuestaDetalleGraficoDistribucionCuota {
    display: block;
    grid-column: 2;
    grid-row: 5/9;
    width: 80%;
    max-height: 500px;
    margin-top: 1%;
    align-content: center;
    border: solid;
    border-style: groove;
}

.propuestaDetalleTablaComportamientoDeuda {
    grid-column: 2;
    grid-row: 10/14;
    width: 80%;
}

.propuestaDetalleTablaSeguros {
    grid-column: 1;
    grid-row: 10/10;
    width: 80%;
}

.propuestaDetalleObservaciones {
    grid-column: 2;
    grid-row: 13;
    width: 80%;
}

.propuestaDetalleGraficoComportamientoSeguros {
    display:block;
    grid-column: 1;
    grid-row: 12/16;
    width: 80%;
    max-height: 350px;
    align-content: center;
    border: solid;
    border-style:groove;
}

.propuestaDetalleNegrita {
    color: #0B6D8D;
    font-weight: bold;
}

.propuestaDetalleObservacionesValida {
    color: #E46C0A;
    font-weight: bold;
}
.error404Fondo {
    position: fixed;
    width: 100%;
    height: 89%;
    max-width: 100%;
    background: linear-gradient(#0B6D8D, #1A8DB6 );
    overflow-x: auto;
    overflow-y: auto;
}

.error-container {
    text-align: center;
    font-size: 180px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 800;
    margin: 20px 15px;
    min-width: 450px;
    max-width: 100%;
}

.error-titulo{
    max-width:100%;
}

.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}

.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.error-container > span:nth-of-type(1) {
  color: #D1F2A5;
  -webkit-animation: colordancing 4s infinite;
          animation: colordancing 4s infinite;
}

.error-container > span:nth-of-type(3) {
  color: #F56991;
  -webkit-animation: colordancing2 4s infinite;
          animation: colordancing2 4s infinite;
}

.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}

.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
	border-radius: 0%;
	content:"";
	position: absolute;
	top: 0; left: 0;
	width: inherit; height: inherit;
  border-radius: 999px;
	box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  -webkit-animation: shadowsdancing 4s infinite;
          animation: shadowsdancing 4s infinite;
}

.error-container > span:nth-of-type(2):before {
	-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}

@-webkit-keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
				inset 0 30px 0 rgba(209, 242, 165, 0.4),
				inset -30px 0 0 rgba(239, 250, 180, 0.4),	
				inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
				inset 0 30px 0 rgba(245, 105, 145, 0.4),
				inset -30px 0 0 rgba(209, 242, 165, 0.4),	
				inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
				inset 0 30px 0 rgba(255, 196, 140, 0.4),
				inset -30px 0 0 rgba(245, 105, 145, 0.4),	
				inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}

@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
				inset 0 30px 0 rgba(209, 242, 165, 0.4),
				inset -30px 0 0 rgba(239, 250, 180, 0.4),	
				inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
				inset 0 30px 0 rgba(245, 105, 145, 0.4),
				inset -30px 0 0 rgba(209, 242, 165, 0.4),	
				inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
				inset 0 30px 0 rgba(255, 196, 140, 0.4),
				inset -30px 0 0 rgba(245, 105, 145, 0.4),	
				inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}

@-webkit-keyframes colordancing {
    0% {
        color: #B38B9F;
    }
    25% {
        color: #E78357;
    }
    50% {
        color: #AE8BA3;
    }
    75% {
        color: #117A9E;
    }
    100% {
        color: #AE8BA3;
    }
}

@keyframes colordancing {
    0% {
        color: #B38B9F;
    }
    25% {
        color: #E78357;
    }
    50% {
        color: #AE8BA3;
    }
    75% {
        color: #117A9E;
    }
    100% {
        color: #AE8BA3;
    }
}

@-webkit-keyframes colordancing2 {
    0% {
        color: #AE8BA3;
    }
    25% {
        color: #117A9E;
    }
    50% {
        color: #AE8BA3;
    }
    75% {
        color: #E78357;
    }
    100% {
        color: #B38B9F;
    }
}

@keyframes colordancing2 {
    0% {
        color: #AE8BA3;
    }
    25% {
        color: #117A9E;
    }
    50% {
        color: #AE8BA3;
    }
    75% {
        color: #E78357;
    }
    100% {
        color: #B38B9F;
    }
}

.error404Titulo {
    text-align: center;
    justify-content:center;
    width:100%;
    color:white;
}

.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}

.link-container {
  text-align: center;
}

a.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #92a4ad;
    padding: 10px 15px;
    border-radius: 0;
    color: white;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}

    a.more-link:hover {
        background-color: #F5811E;
        color:black;

    }

    a.more-link:active {
        background-color: rgba(245, 129, 30, 0.75);
        color: black;
    }
